







































































































import { Vue, Component } from 'vue-property-decorator'
import { ReleaseDTO, ReleaseStatus } from '@/api-client'
import { PRODUCT_NAME } from '@/modules/config'
import { SHOW_MESSAGE } from '@/store/snackbar'

import VueFuse from 'vue-fuse'
import ProgressCentered from '@/components/ProgressCentered.vue'
import DownloadButton from '@/components/inputs/DownloadButton.vue'
import ReleaseCard from '@/components/ReleaseCard.vue'
import ValidationMessages from '@/components/ValidationMessages.vue'
import ReleaseStatusCustom from '@/types/ReleaseStatus'

enum SortValue {
  LATEST = 'Latest',
  STATUS = 'Status',
  NAME = 'Name',
}

interface FuseRelease {
  name: string
  status: ReleaseStatus
  developer?: string
  project?: string
  id: string
}

@Component({
  components: {
    ValidationMessages,
    ReleaseCard,
    DownloadButton,
    ProgressCentered,
    VueFuse,
  },
  head: {
    title() {
      return {
        inner: 'All releases',
      }
    },
    style: [
      {
        type: 'text/css',
        inner: `.v-main {
          background: url('/img/seller-background.svg');
          background-size: 650%;
          background-position: 5.5% 0%;
          background-attachment: fixed;
        }`,
      },
    ],
  },
})
export default class Releases extends Vue {
  PRODUCT_NAME = PRODUCT_NAME

  loading = false
  releases: ReleaseDTO[] = []
  addReleaseErrors: string[] = []

  searchTerm = ''
  sortValue = SortValue.LATEST

  sortValues = Object.values(SortValue)

  searchedReleases: ReleaseDTO[] = []

  fuseOptions = {
    keys: [
      {
        name: 'name',
        weight: 4,
      },
      {
        name: 'status',
        weight: 2,
      },
      'developer',
      'project',
    ],
    includeScore: false,
    threshold: 0.6,
  }

  async addRelease() {
    this.loading = true
    this.addReleaseErrors = []
    await this.$api.release
      .v1ReleasesPost()
      .then(res => {
        this.$router.push(`/releases/${res.data.id}`)
      })
      .catch(() => {
        this.addReleaseErrors = ['An error occurred']
      })
    this.loading = false
  }

  get fuzzySearchData(): FuseRelease[] {
    return this.releases.map(r => ({
      name: r.details?.name ?? 'Untitled release',
      status: r.status,
      developer: r.details?.developerName,
      project: r.details?.projectName,
      id: r.id,
    }))
  }

  get sortedSearchedReleases() {
    return this.searchedReleases.sort((a, b) => {
      const aName = a.details?.name ?? 'Untitled release'
      const bName = b.details?.name ?? 'Untitled release'
      return {
        [SortValue.LATEST]:
          this.releases.findIndex(r => r.id === b.id) -
          this.releases.findIndex(r => r.id === a.id),
        [SortValue.STATUS]: a.status - b.status,
        [SortValue.NAME]: aName.localeCompare(bName),
      }[this.sortValue]
    })
  }

  handleSearch(results: { item: FuseRelease; refIndex: number }[]) {
    this.searchedReleases = results.map(
      res => this.releases.find(r => r.id === res.item.id)!,
    )
  }

  beforeMount() {
    this.$api.release
      .v1ReleasesGet()
      .then(({ data }) => {
        this.releases = (data as unknown) as ReleaseDTO[]
        this.loading = false
      })
      .catch(err => {
        this.$router.push({
          path: '/error',
          params: { statusCode: '500', message: err.message },
        })
      })
    if (this.$route.query.reset)
      this.$store.commit(
        SHOW_MESSAGE,
        'Your password has been reset. You can now sign in with your new password.',
      )
  }

  deleteRelease(releaseId: string) {
    this.$delete(
      this.releases,
      this.releases.findIndex(r => r.id === releaseId),
    )
  }

  allocateRelease(releaseId: string) {
    const releaseIndex = this.releases.findIndex(r => r.id === releaseId)!
    const release = this.releases[releaseIndex]
    this.$set(this.releases, releaseIndex, {
      ...release,
      status: ReleaseStatusCustom.Allocated,
    })
  }

  publishRelease(releaseId: string) {
    const releaseIndex = this.releases.findIndex(r => r.id === releaseId)!
    const release = this.releases[releaseIndex]
    this.$set(this.releases, releaseIndex, {
      ...release,
      status: ReleaseStatusCustom.Published,
    })
  }

  completeRelease(releaseId: string) {
    const releaseIndex = this.releases.findIndex(r => r.id === releaseId)!
    const release = this.releases[releaseIndex]
    this.$set(this.releases, releaseIndex, {
      ...release,
      status: ReleaseStatusCustom.Complete,
    })
  }
}
