































































































import ReleaseStatusChip from './ReleaseStatusChip.vue'
import { ReleaseDTO } from '@/api-client'
import { parseUtcDate } from '@/modules/date'
import { format, formatDistanceToNow, isFuture } from 'date-fns'
import { Vue, Component, Prop } from 'vue-property-decorator'
import ReleaseStatus from '@/types/ReleaseStatus'
import { SHOW_MESSAGE } from '@/store/snackbar'
import ProgressCentered from '@/components/ProgressCentered.vue'

@Component({
  components: {
    ReleaseStatusChip,
    ProgressCentered,
  },
})
export default class ReleaseCard extends Vue {
  @Prop({
    required: true,
    type: Object,
  })
  release!: ReleaseDTO

  ReleaseStatus = ReleaseStatus

  showCardMenu = false
  loading = false

  goToRelease() {
    if (!this.loading) this.$router.push(`/releases/${this.release.id}`)
  }

  get projectImageUrl() {
    return this.release.projectImage?.url ?? '/img/logo-placeholder.svg'
  }

  get releaseStatus() {
    if (
      this.release.status === ReleaseStatus.Published &&
      !this.isCloseDateInFuture
    ) {
      return this.ReleaseStatus.Allocated
    }
    return this.release.status
  }

  get numApplicationsSubmitted() {
    return this.release.numberOfApplications
  }

  get skipAllocationPhase() {
    return this.release.details?.skipAllocationsPhase
  }

  get onDemandPurchasingEnabled() {
    return this.release.details?.onDemandPurchasingEnabled
  }

  get releaseType() {
    if (this.skipAllocationPhase && this.onDemandPurchasingEnabled) {
      return 'Buy Now'
    }
    if (!this.skipAllocationPhase && this.onDemandPurchasingEnabled) {
      return 'Release and Buy Now'
    }
    return 'Release'
  }

  get closeDate() {
    if (this.release.status == ReleaseStatus.OnDemandPurchasing) {
      return null
    }
    if (this.release.details) {
      if (this.release.details.skipAllocationsPhase) {
        return null
      }
      return parseUtcDate(this.release.details.applicationsCloseDate)
    }
    return null
  }

  get isCloseDateInFuture() {
    return this.closeDate ? isFuture(this.closeDate) : false
  }

  get formattedCloseDate() {
    return format(this.closeDate!, 'dd/LL/yyyy, HH:mm')
  }

  get countdownString(): string {
    return this.closeDate ? formatDistanceToNow(this.closeDate) : ''
  }

  get timeLeft() {
    if (!this.closeDate) return ''
    return {
      [ReleaseStatus.Draft]: this.isCloseDateInFuture
        ? `${this.countdownString} left`
        : 'Already passed, needs to be updated',
      [ReleaseStatus.Published]: this.isCloseDateInFuture
        ? `${this.countdownString} left`
        : `Closed on ${this.formattedCloseDate}`,
      [ReleaseStatus.Allocated]: `Closed on ${this.formattedCloseDate}`,
      [ReleaseStatus.Completed]: `Closed on ${this.formattedCloseDate}`,
      [ReleaseStatus.Cancelled]: '',
    }[this.release.status]
  }

  get hasAllocationStep() {
    return this.release.details?.skipAllocationsPhase === false
  }

  get hasBuyNowStep() {
    return this.release.details?.onDemandPurchasingEnabled === true
  }

  get publishStepText() {
    return !this.hasAllocationStep
      ? 'Force buy now (Demo only)'
      : 'Force publish (Demo only)'
  }

  get completeStepText() {
    return this.hasBuyNowStep &&
      [ReleaseStatus.Published, ReleaseStatus.Allocated].includes(
        this.releaseStatus,
      )
      ? 'Force buy now (Demo only)'
      : 'Force complete (Demo only)'
  }

  get cardMenuItems() {
    const items: { text: string; action: Function }[] = []
    if (this.release.status === ReleaseStatus.Draft)
      items.push({
        text: 'Delete draft',
        action: () => {
          this.loading = true
          this.$api.release
            .v1ReleasesReleaseIdDelete(this.release.id)
            .then(() => {
              this.$emit('delete', this.release.id)
            })
            .catch(() => {
              this.$store.dispatch(SHOW_MESSAGE, 'Failed to delete release')
            })
            .finally(() => {
              this.loading = false
            })
        },
      })
    if (process.env.NODE_ENV !== 'production' && this.release.details) {
      items.push({
        text: this.publishStepText,
        action: () => {
          this.$api.release
            .v1ReleasesReleaseIdForcePublishPost(this.release.id)
            .then(() => {
              this.$emit('publish', this.release.id)
            })
            .catch(() => {
              this.$store.dispatch(SHOW_MESSAGE, 'Failed to publish release')
            })
            .finally(() => {
              this.loading = false
            })
        },
      })
      if (this.hasAllocationStep) {
        items.push(
          {
            text: 'Force allocation (Demo only)',
            action: () => {
              this.$api.release
                .v1ReleasesReleaseIdForceAllocatePost(this.release.id)
                .then(() => {
                  this.$emit('allocate', this.release.id)
                })
                .catch(() => {
                  this.$store.dispatch(
                    SHOW_MESSAGE,
                    'Failed to allocate release',
                  )
                })
                .finally(() => {
                  this.loading = false
                })
            },
          },
          {
            text: 'Force reminder (Demo only)',
            action: () => {
              this.$api.release
                .v1ReleasesReleaseIdForceReminderPost(this.release.id)
                .catch(() => {
                  this.$store.dispatch(SHOW_MESSAGE, 'Failed to send reminder')
                })
                .finally(() => {
                  this.loading = false
                })
            },
          },
        )
      }

      items.push({
        text: this.completeStepText,
        action: () => {
          this.$api.release
            .v1ReleasesReleaseIdForceCompletePost(this.release.id)
            .then(() => {
              this.$emit('complete', this.release.id)
            })
            .catch(() => {
              this.$store.dispatch(SHOW_MESSAGE, 'Failed to complete release')
            })
            .finally(() => {
              this.loading = false
            })
        },
      })
    }
    return items
  }
}
